* {
  font-family: Helvetica, sans-serif; 
  box-sizing: border-box;
  margin: 0;
}

html {
  /* height: 100%; */
  /* position: fixed; */
/* width: -webkit-fill-available; */
  height: -webkit-fill-available;
  width: -webkit-fill-available;
}
#root {
  min-height: 100%;
  min-width: 100%;
}
body {
  /* overflow-x: hidden;
  overflow-y: scroll; */
  /* position: fixed; */
  /* overflow: hidden; */
  width: 100%;
  /* height: 100vh; */
  min-height: 100vh;
  min-width: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  min-width: -webkit-fill-available;
  /* width: calc(var(--vw, 1vw) * 100); */

  background-color: hwb(0deg 83% 17%);
  /* background-color: black; */
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  display: none;
}
article::-webkit-scrollbar {
  display: none;
}


header {
  grid-area: header;
  z-index: 3;
  height: 130px;
  background-color: gainsboro;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 6px;
  border-left-width: 0px;
  border-bottom-left-radius: 22px;
  margin-bottom: 6px;
  box-shadow: -3px 3px 5px 2px #aaaaaa;
}


main {
  grid-area: main;
  background-color: gainsboro;
  border-radius: 22px 22px 22px 22px;
  box-shadow: -3px 3px 5px 2px #aaaaaa;
  display: block;
  width: -webkit-fill-available;
  /* width: fit-content; */
  /* overflow: hidden; */
  /* display: unset; */
  /* align-items: center; */
  
}
main div{
  /* display: flex; */
}
aside:nth-child(1) {
  display: block;
  position: relative;
  z-index: 5;
  margin-right: 5px;
  grid-area: leftbar;
  background-color: gainsboro;
  border-top-width: 0px;
  border-right-width: 6px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  box-shadow: -3px 3px 5px 2px #aaaaaa;
  border-bottom-right-radius: 22px;
}


.grid-wrapper::-webkit-scrollbar {
  display: none;
}
aside:nth-child(3) {
  grid-area: rightbar;
  background-color: gainsboro;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  /* border-left-width: 6px; */
  border-radius: 22px 0px 0px 22px;
  box-shadow: -3px 3px 5px 2px #aaaaaa;
  /* margin-left: 6px; */
}

@media (max-width: 1100px) {
  aside:nth-child(3) {
    /* margin-left: 0px; */
  }
  }
  html.ios {
    overflow-x: hidden;
    -webkit-overflow-scrolling: auto !important;
  }
  html.ios,
  html.ios body {
      height: initial !important;
  }

@media (min-width: 1100px) {
  .grid-wrapper {
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: initial;
  }
  aside:nth-child(3) {
    /* margin-left: 0px; */
    margin-left: 6px;
  }
  }
  /* background-color: #474747; */
  @media (max-width: 700px) {
    aside:nth-child(1) {
      display: flex;
      flex-direction: column-reverse;
    }
    a {
      /* font-size: 2rem; */
      text-align: left;
      width: 240px;
      color: black;
    }
    

    header {
      height: 0px;
      z-index: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
    main{
      display: flex;
      justify-content: center;
      overflow: hidden;
    }
    body {
      background-color: black;
    }
    article {
      padding-inline: 0;
      padding-top: 0px;
    }
    /* article .one {
      justify-items: right;
    }
    article .two {
      justify-items: left;
    } */
    .grid-wrapper {
      position: relative;
      z-index: 0;
      transform: translate3d(0, 0, 0);
      -webkit-overflow-scrolling: auto !important;
      -webkit-backface-visibility: hidden;
      
      overflow: scroll;
      /* height: -webkit-fill-available; */
      height: 100vh;
      width: 100vw;
      /* padding-block: 20vh;
      padding-inline: 40vw; */
      /* white-space: nowrap; */
    }
    
    .title-cont {
      font-size: 1rem;
      line-height: 1em;
    }
    .nav-bar-header{
      all: unset;
      font-size: 1.3rem;
      color: gainsboro;
      line-height: 1;
      margin-bottom: 0px;
      cursor: pointer;
    }

    }
    @media screen and (min-width: 700px) {
      article {
        padding-inline: max(10%);
        padding-top: 80px;
      }
      .grid-wrapper{
        display: flex;
        justify-content: center;
      }
      a {
        font-size: 2rem;
        text-align: left;
        width: 240px;
        color: black;
      }
      /* .grid-item:nth-child(4n+1) {
        
    } */
    
      .nav-bar-header{
        font-size: 24px;
        line-height: 1.3;
        margin-bottom: 0px;
        cursor: pointer;

        /* font-style: italic;   
      -moz-text-fill-color:gainsboro;
      -webkit-text-fill-color: gainsboro;
      -moz-text-stroke-color: black;
      -moz-text-stroke-width: 1.5px;  
      -webkit-text-stroke-color: black;
      -webkit-text-stroke-width: 1.5px;
      font-size: 35px;
      line-height: 1.2;
      margin-bottom: 0px;
      cursor: pointer;
      text-shadow: 0px 0px #212529;
        transition: all 0.5s ease-in .2s; */
    }
    /* .nav-bar-header:hover {
        transition: all 0.2s ease-out;
    
        transform: translate(3px, -3px); 
        text-shadow:
        -3px 3px #212529,
        -2.75px 2.75px #212529,
        -2.5px 2.5px #212529,
        -2.25px 2.25px #212529,
        -2px 2px #212529,
        -1.75px 1.75px #212529,
        -1.5px 1.5px #212529,
        -1.25px 1.25px #212529,
        -1px 1px #212529,
        -0.75px 0.75px #212529,
        -0.5px 0.5px #212529,
        -0.25px 0.25px #212529
    
      } */
    }
article {
  /* height: 95rem;
  min-width: 700px; */
  height: fit-content;
  width: -webkit-fill-available;
  /* overflow-x: visible; */
}



button {
  background-color: gainsboro;
  
}
.catagory-cont {
  height: fit-content;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.sidebar-catagory {
  display:flex;
  justify-content: space-between;  
}
.title-cont {
  display:flex;
  justify-content: space-between;
  width: 100%;
  
  /* padding-inline: 6px; */
  padding-right: 10px;
}

.asterisc{
  color: #757575;
  padding-left: 10px;
  font-size: .75rem;
  padding-top: 20px;
}

.close-modal-button {
  cursor: pointer;
  background-color: transparent;
  color: white;
  transform: rotate(-45deg);
  border: none;
  font-size: 35px;
  line-height: 0px;
  position: absolute;
  right: 2px;
  top:11px;
}

.bottom-curtain {
  position: relative;
  width: 100%;
  height: 7.5rem;
  z-index: 1;
  bottom: 7rem;
  background: gainsboro;
}
.sidebar-break {
  width: 100%;
}

input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 50px gainsboro inset;
}

.slide-button-left {
  margin-top: 5px;
  margin-left: .3vw ;
  transition: 1s;
  position: absolute;
  z-index: 1;
  font-size: 15px;
}

  .slide-button-right {
    margin-top: 5px;

    margin-left: max(14.6vw, 172px);   

    transition: 1s;
    position: absolute;
    z-index: 1;
    font-size: 15px;
  }

.about-me {
    font-size: 1rem;  
  }
  *:focus {
    outline: none;
}
  input {
    background-color: inherit;

    border-width: 0;
    margin-top: 0;
  }
  input .name-form {
    font-size: 3.5rem;
    text-align: right;
    font-family: "HelveticaNeue-Light";
    font-weight: normal;
    padding-right: 20px;
  }
  textarea {
    margin: 0;
    display: block;
    line-height: none;
  }
  

  .nav-bar-header-wrapper{
    /* display: inline-flex */
    width: 100%;
  }
  
  

  




  .modal {
    padding:100px;
    position: fixed;
    z-index: 5;
    top :0;
    left:0;
    right:0;
    bottom:0;
    background: rgba(0, 0, 0, 0.8);

    outline: none;
    padding: 3.2rem;
    padding: 100px;
  }

  .next-button-right {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
  }
  .next-button-left {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 50%;
  }
  .next-button-left, .next-button-right {
    padding: 10px 24px;
    background:none;
    color: #fff;
    border: none;
    z-index: 1;
    font-size: 30px;  
    display:inline-block;

  }

.exit-modal-button {
    padding: 10px 24px;
    background:none;
    color: #fff;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;

}
  






